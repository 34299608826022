.about__container {
    display        : flex;
    height         : fit-content;
    width          : 100vw;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    color          : white;
    font-weight    : bold;
    font-size      : 5vw;

}

.about__main__elements {
    padding         : 1rem;
    border-radius   : 30px;
    background-color: #030a25;
    display         : flex;
    height          : fit-content;
    width           : 90vw;
    align-items     : center;
    flex-direction  : column;
    justify-content : center;
}

.expanded__about__box {
    width         : 85vw;
    height        : 65vh;
    display       : flex;
    flex-direction: column;
    padding       : 1rem;
    border-radius : 30px;
    border        : solid white 3px;

    .expanded__about__top {
        font-size      : 5vw;
        width          : 85vw;
        height         : fit-content;
        display        : flex;
        justify-content: space-between;
        flex-direction : column;

        .close__button {
            width : 5vw;
            cursor: pointer;
        }
    }

    .expanded__middle {
        width          : 85vw;
        height         : 50vh;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        flex-direction : row;

        .expanded__about__left {
            width          : 40vw;
            height         : 35vh;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            justify-content: center;

            .left__title {
                font-size: 6vw;
            }

            .left__text {
                font-size  : 2vw;
                font-weight: normal;
                width      : 40vw;
            }
        }

        .expanded__about__right {
            width : 30vw;
            height: 60vh;
        }
    }


}

.about__boxes {
    width          : 90vw;
    height         : 50vh;
    max-height     : 500px;
    display        : flex;
    align-items    : center;
    flex-direction : row;
    justify-content: space-evenly;
}

.about__box {
    padding      : 1rem;
    border-radius: 30px;
    width        : 25vw;
    height       : 40vh;
    min-height   : fit-content;
    max-height   : 300px;
    border       : solid 3px white;
    transition   : all 0.1s ease-in-out;
}

.about__box:hover {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.8);
    cursor    : pointer;
}

.about__box__topper {
    display        : flex;
    align-items    : center;
    justify-content: space-evenly;
    flex-direction : row;


    .about__box__title {
        font-size: 3vw;
    }
}

.about__box__inner {
    font-size  : 2.5vw;
    font-weight: normal;
}

hr {
    margin-top: 2vh;
}

@media screen and (min-width: 1600px) {

    .about__boxes {
        padding        : 1rem;
        width          : 90vw;
        height         : fit-content;
        max-height     : 500px;
        display        : flex;
        align-items    : center;
        flex-direction : row;
        justify-content: space-evenly;
    }

    .about__box {
        padding      : 1rem;
        border-radius: 30px;
        width        : 25vw;
        height       : 50vh;
        min-height   : fit-content;
        max-height   : 600px;
        border       : solid 3px white;
        transition   : all 0.1s ease-in-out;
    }
}


@media screen and (max-width: 992px) {
    .about__container {
        display        : flex;
        height         : fit-content;
        width          : 100vw;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        color          : white;
        font-weight    : bold;
        font-size      : 5vw;

    }

    .about__main__elements {
        padding         : 1rem;
        border-radius   : 30px;
        background-color: #030a25;
        display         : flex;
        height          : fit-content;
        width           : 90vw;
        align-items     : center;
        flex-direction  : column;
        justify-content : center;
    }

    .about__title {
        font-size: 5rem;
    }

    .expanded__about__box {
        width         : 85vw;
        height        : 40vh;
        display       : flex;
        flex-direction: column;
        padding       : 1rem;
        border-radius : 30px;
        border        : solid white 3px;

        .expanded__about__top {
            font-size      : 5vw;
            width          : 85vw;
            height         : fit-content;
            display        : flex;
            justify-content: space-between;
            flex-direction : column;

            .close__button {
                width : 5vw;
                cursor: pointer;
            }
        }

        .expanded__middle {

            width          : 60vw;
            height         : 50vh;
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            flex-direction : row;

            .expanded__about__left {
                width          : 40vw;
                height         : 35vh;
                display        : flex;
                flex-direction : column;
                align-items    : flex-start;
                justify-content: center;

                .left__title {
                    font-size: 6vw;
                }

                .left__text {
                    font-size  : 2vw;
                    font-weight: normal;
                    width      : 40vw;
                }
            }

            .expanded__about__right {
                width : 30vw;
                height: 60vh;
            }
        }


    }

    .about__boxes {
        width          : 100%;
        overflow-x     : auto;
        white-space    : nowrap;
        height         : vh;
        display        : flex;
        align-items    : center;
        flex-direction : row;
        justify-content: space-evenly;
    }

    .about__box {
        margin       : 0 1.5rem 0 1.5rem;
        padding      : 1rem;
        border-radius: 30px;
        min-width    : 80vw;
        height       : 40vh;
        max-height   : 400px;
        border       : solid 3px white;
        transition   : all 0.1s ease-in-out;
    }

    .about__box:hover {
        box-shadow: none;
        cursor    : default;
    }

    .about__box__topper {
        display        : flex;
        align-items    : center;
        justify-content: space-evenly;
        flex-direction : row;


        .about__box__title {
            font-size: 8vw;
        }
    }

    .about__box__inner {
        font-size  : 6vw;
        font-weight: normal;
        white-space: normal;
        word-wrap  : break-word;
    }

    hr {
        margin-top: 2vh;
    }
}

@media screen and (max-width: 600px) {
    .about__container {
        display        : flex;
        height         : fit-content;
        width          : 100vw;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        color          : white;
        font-weight    : bold;
        font-size      : 5vw;

    }

    .about__main__elements {
        padding         : 1rem;
        border-radius   : 30px;
        background-color: #030a25;
        display         : flex;
        height          : fit-content;
        width           : 90vw;
        align-items     : center;
        flex-direction  : column;
        justify-content : center;
    }

    .about__title {
        font-size: 3rem;
    }

    .expanded__about__box {
        width         : 85vw;
        height        : 65vh;
        display       : flex;
        flex-direction: column;
        padding       : 1rem;
        border-radius : 30px;
        border        : solid white 3px;

        .expanded__about__top {
            font-size      : 5vw;
            width          : 85vw;
            height         : fit-content;
            display        : flex;
            justify-content: space-between;
            flex-direction : column;

            .close__button {
                width : 5vw;
                cursor: pointer;
            }
        }

        .expanded__middle {
            width          : 85vw;
            height         : 50vh;
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            flex-direction : row;

            .expanded__about__left {
                width          : 40vw;
                height         : 35vh;
                display        : flex;
                flex-direction : column;
                align-items    : flex-start;
                justify-content: center;

                .left__title {
                    font-size: 6vw;
                }

                .left__text {
                    font-size  : 2vw;
                    font-weight: normal;
                    width      : 40vw;
                }
            }

            .expanded__about__right {
                width : 30vw;
                height: 60vh;
            }
        }


    }

    .about__boxes {
        width          : 100%;
        overflow-x     : auto;
        white-space    : nowrap;
        height         : 50vh;
        display        : flex;
        align-items    : center;
        flex-direction : row;
        justify-content: space-evenly;
    }



    .about__box {
        margin       : 0 1rem 0 1rem;
        padding      : 1rem;
        border-radius: 30px;
        min-width    : 80vw;
        height       : 40vh;
        border       : solid 3px white;
        transition   : all 0.1s ease-in-out;
    }

    .about__box:hover {
        box-shadow: none;
        cursor    : default;
    }

    .about__box:first-child {
        margin-left: 0rem;
    }

    .about__box:last-child {
        margin-right: 0rem;
    }

    .about__box__topper {
        display        : flex;
        align-items    : center;
        justify-content: space-evenly;
        flex-direction : row;


        .about__box__title {
            font-size: 10vw;
        }
    }

    .about__box__inner {
        font-size  : 7vw;
        font-weight: normal;
        white-space: normal;
        word-wrap  : break-word;
    }

    hr {
        margin-top: 2vh;
    }
}