.footer__container {
    padding            : 1rem 1rem 0rem 1rem;
    height             : 100%;
    display            : flex;
    align-items        : flex-end;
    justify-content    : center;
    /* background-color:  rgb(255, 196, 86);; */
}


.footer__inner__box {
    padding         : 1.5rem;
    width           : 80vw;
    height          : 40vh;
    max-height      : 300px;
    min-height      : 250px;
    background-color: orange;
    border-radius   : 50px 50px 0 0;
}

.footer__inner__title {
    font-size  : 40px;
    font-weight: bold;
    color      : white
}

.footer__socials {
    margin-top     : 5rem;
    display        : flex;
    align-items    : center;
    justify-content: center
}

.footer__socials__box {
    width          : 50vw;
    display        : flex;
    align-items    : center;
    justify-content: space-around
}

.socials__img {
    width: 10vw;
}

.footer__copyright__box {
    position       : fixed;
    bottom         : 0;
    left           : 0;
    width          : 100%;
    height         : 9vh;
    display        : flex;
    align-items    : flex-end;
    justify-content: center;
}

.footer__copyright {
    padding         : 0.2rem;
    color           : white;
    background-color: rgb(208, 135, 0);
    border-radius   : 10px 10px 0 0;
}

@media screen and (max-width: 992px) {
    .footer__container {
        height         : 30vh;
        display        : flex;
        align-items    : flex-end;
        justify-content: center;
    }


    .footer__inner__box {
        padding         : 1.5rem;
        width           : 80vw;
        background-color: orange;
        border-radius   : 50px 50px 0 0;
    }

    .footer__inner__title {
        font-size  : 8vw;
        font-weight: bold;
        color      : white;
        text-align : center;
    }

    .footer__socials {
        margin-top     : 2rem;
        display        : flex;
        align-items    : center;
        justify-content: center
    }

    .footer__socials__box {
        width          : 70vw;
        display        : flex;
        align-items    : center;
        justify-content: space-between
    }

    .socials__img {
        width: 15vw;
    }

    .footer__copyright__box {
        position       : fixed;
        bottom         : 0;
        left           : 0;
        width          : 100%;
        height         : 5vh;
        display        : flex;
        align-items    : flex-end;
        justify-content: center;
    }

    .footer__copyright {
        font-size       : 3vw;
        padding         : 0.2rem;
        color           : white;
        background-color: rgb(208, 135, 0);
        border-radius   : 10px 10px 0 0;
    }
}

@media screen and (max-width: 700px) {
    .footer__container {
        height         : 15vh;
        display        : flex;
        align-items    : flex-end;
        justify-content: center;
    }


    .footer__inner__box {
        max-height      : 250px;
        min-height      : 180px;
        padding         : 1.5rem;
        width           : 80vw;
        height          : 20vh;
        background-color: orange;
        border-radius   : 50px 50px 0 0;
    }

    .footer__inner__title {
        font-size  : 8vw;
        font-weight: bold;
        color      : white;
        text-align : center;
    }

    .footer__socials {
        margin-top     : 2rem;
        display        : flex;
        align-items    : center;
        justify-content: center
    }

    .footer__socials__box {
        width          : 70vw;
        display        : flex;
        align-items    : center;
        justify-content: space-between
    }

    .socials__img {
        width: 15vw;
    }

    .footer__copyright__box {
        position       : fixed;
        bottom         : 0;
        left           : 0;
        width          : 100%;
        height         : 5vh;
        display        : flex;
        align-items    : flex-end;
        justify-content: center;
    }

    .footer__copyright {
        font-size       : 3vw;
        padding         : 0.2rem;
        color           : white;
        background-color: rgb(208, 135, 0);
        border-radius   : 10px 10px 0 0;
    }
}