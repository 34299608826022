.playoff__container {
    display        : flex;
    height         : 100vh;
    width          : 100vw;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    color          : white;
    font-weight    : bold;
    font-size      : 5vw;

}

.playoff__main__elements {
    padding        : 1rem;
    border-radius  : 30px;
    display        : flex;
    height         : 100vh;
    width          : 100vw;
    align-items    : center;
    flex-direction : column;
    justify-content: center;
}