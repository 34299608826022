.minigame__container {
    display        : flex;
    height         : 80vh;
    width          : 100vw;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    color          : white;
    font-weight    : bold;
    font-size      : 5vw;
}

.minigame__main__elements {
    padding        : 1rem;
    border-radius  : 30px;
    display        : flex;
    height         : 80vh;
    width          : 80vw;
    align-items    : center;
    flex-direction : column;
    justify-content: center;
}


.spline__dribble {
    width: 100vw;
}