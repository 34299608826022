.home__container {
  display        : flex;
  height         : 100vh;
  align-items    : center;
  justify-content: center;
  overflow-x     : hidden;
  max-width      : 100vw;
}

.home__main__elements {
  display        : flex;
  align-items    : center;
  justify-content: center;
  flex-direction : column;
}

.home__title__box {
  width          : 100vw;
  display        : flex;
  align-items    : center;
  justify-content: space-around;

}

.home__intro__box {
  padding        : 3rem;
  justify-content: center;
  width          : 70vw;
  height         : 50vh;
  display        : flex;
  margin-left    : 5rem;
  border-radius  : 30px;
  flex-direction : column;
  color          : white;
  font-size      : 3.5vw;


  .home__intro__title {
    font-weight: bold;
    font-size  : 8vw;

  }

  a {
    text-decoration: none;
    width          : fit-content;
  }


  .home__button {
    padding         : 0;
    color           : white;
    font-size       : 2vw;
    margin-top      : 1rem;
    width           : 24vw;
    height          : 9vh;
    border-radius   : 30px;
    background-color: orange;
    border          : white solid 2px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    cursor          : pointer;



    img {
      margin-left: 1rem;
      width      : 4vw;
      max-width  : 70px;
    }
  }
}

.spline__scene {
  width          : 100%;
  display        : flex;
  align-items    : center;
  justify-content: space-around;

}

.home__title {
  font-size  : 10vw;
  font-weight: bold;
}




/* =============== BREAKPOINTS =============== */
/* For large devices */
@media screen and (max-width: 992px) {



  .home__container {
    display        : flex;
    height         : 100vh;
    align-items    : flex-start;
    margin-top     : 10rem;
    justify-content: center;
    overflow-x     : hidden;
    max-width      : 100vw;
  }

  .home__title__box {
    width          : 100vw;
    display        : flex;
    align-items    : center;
    justify-content: space-around;
    flex-direction : column-reverse;

  }

  .home__intro__box {
    padding        : 3rem;
    justify-content: center;
    align-items    : center;
    width          : 100vw;
    height         : fit-content;
    display        : flex;
    margin-left    : 0rem;
    border-radius  : 30px;
    flex-direction : column;
    color          : white;
    font-size      : 5vw;


    .home__intro__title {
      font-weight: bold;
      font-size  : 13vw;

    }

    a {
      text-decoration: none;
      width          : fit-content;
    }


    .home__button {
      padding         : 3rem;
      color           : white;
      font-size       : 4vw;
      margin-top      : 3rem;
      width           : 100%;
      height          : 5rem;
      border-radius   : 50px;
      background-color: orange;
      border          : white solid 2px;
      display         : flex;
      align-items     : center;
      justify-content : center;
      cursor          : pointer;



      img {
        margin-left: 1rem;
        width      : 5vw;
      }
    }
  }

  .home__title {
    font-size  : 10vw;
    font-weight: bold;
  }

  .spline__scene {
    width          : 100%;
    display        : flex;
    align-items    : center;
    justify-content: space-around;

  }
}






@media screen and (max-width: 700px) {
  .home__container {
    display        : flex;
    height         : 100vh;
    align-items    : flex-start;
    margin-top     : 10rem;
    justify-content: center;
    overflow-x     : hidden;
    max-width      : 100vw;
  }

  .home__title__box {
    width          : 100vw;
    display        : flex;
    align-items    : center;
    justify-content: space-around;
    flex-direction : column-reverse;

  }

  .home__intro__box {
    padding        : 3rem;
    justify-content: center;
    align-items    : center;
    width          : 100vw;
    height         : fit-content;
    display        : flex;
    margin-left    : 0rem;
    border-radius  : 30px;
    flex-direction : column;
    color          : white;
    font-size      : 5vw;


    .home__intro__title {
      font-weight: bold;
      font-size  : 13vw;

    }

    a {
      text-decoration: none;
      width          : fit-content;
    }


    .home__button {
      padding         : 0;
      color           : white;
      font-size       : 4vw;
      margin-top      : 1rem;
      width           : 50vw;
      height          : 3rem;
      border-radius   : 30px;
      background-color: orange;
      border          : white solid 2px;
      display         : flex;
      align-items     : center;
      justify-content : center;
      cursor          : pointer;



      img {
        margin-left: 1rem;
        width      : 7vw;
      }
    }
  }

  .spline__scene {
    width          : 100%;
    display        : flex;
    align-items    : center;
    justify-content: space-around;

  }

  .home__title {
    font-size  : 10vw;
    font-weight: bold;
  }
}